<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <span class='subtitle-2'>{{ $t('STATION') }}</span>
      </v-row>
      <v-row>
        <v-autocomplete
          ref='autocomplete'
          v-model='selectedStations'
          :items='stations'
          item-text='name'
          return-object
          :readonly='lockAutocomplete'
          :search-input='autocompleteSearchInput'
          multiple
          chips
          color='primary'
          deletable-chips
          clearable
          @change='setStation'
        >
          <template #selection='data'>
            <v-chip
              :key='data.item'
              :input-value='data.selected'
              v-bind='data.attrs'
              close
              outlined
              color='primary'
              @click:close='removeStation(data.item)'
            >
              {{ data.item }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  export default {
    props: {
      allLocations: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        stations: [],
        autocompleteData: null,
        lockAutocomplete: false,
        autocompleteSearchInput: '',
        selectedStations: [],
      };
    },
    mounted: function () {
      this.fetchData();
    },
    methods: {
      async fetchData () {
        this.stations = this.allLocations;
        this.stations.unshift('Todos');
      },
      applyFilter () {
        this.$emit('applyFilters');
      },
      setStation () {
        const index = this.selectedStations.indexOf('Todos');
        if (index >= 0) {
          const aux = this.selectedStations[index];
          this.selectedStations = [aux];
          this.lockAutocomplete = true;
          this.$refs.autocomplete.isMenuActive = false;
        }
        this.$emit('onFilter', { selectedStations: this.selectedStations });
      },
      removeStation (station) {
        const index = this.selectedStations.findIndex((o) => o === station);
        if (this.selectedStations[index] === 'Todos') {
          this.lockAutocomplete = false;
        }
        this.selectedStations.splice(index, 1);
        this.$emit('onFilter', { selectedStations: this.selectedStations });
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: var(--v-secondary-base) !important;
    color: #FFFFFF !important;
    background-color: var(--v-accent-base) !important;
  }
  .selected-chip span {
    color: var(--v-secondary-base) !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
